<template>
    <span slot="footer" class="dialog-footer px-3">
        <div class="left">
            <el-button
                v-if="!recursive"
                :loading="$wait.is('deleting')"
                type="danger"
                :disabled="!userCan('delete calendar events')"
                @click="deleteBtn"
            >
                {{ $t('calendar.delete_selected') }}
            </el-button>
            <el-dropdown v-else placement="bottom-start" @command="deleteEvents">
                <el-button type="danger" :loading="$wait.is('deleting')">
                    {{ $t('calendar.delete_selected') }}<i class="el-icon-caret-bottom el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="single" :disabled="!userCan('delete calendar events')">
                        {{ $t('calendar.delete_this') }}
                    </el-dropdown-item>
                    <el-dropdown-item command="future" :disabled="!userCan('delete calendar events')">
                        {{ $t('calendar.delete_selected_and_all_future') }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="right">
            <el-button
                v-if="cancelled === 1"
                :loading="$wait.is('updating')"
                type="warning"
                :disabled="!userCan('update calendar events')"
                @click="restore"
            >
                {{ $t('calendar.restore') }}
            </el-button>
            <el-button v-else :loading="$wait.is('updating')" type="warning" :disabled="!userCan('update calendar events')" @click="unbook">
                {{ $t('calendar.unbook') }}
            </el-button>

            <template v-if="eventsConfirmation === 1">
                <el-button
                    v-if="confirmation === 1"
                    :loading="$wait.is('confirming')"
                    :disabled="!userCan('update calendar events')"
                    @click="confirm"
                >
                    {{ $t('common.confirm') }}
                </el-button>
                <el-button v-else :loading="$wait.is('confirming')" :disabled="!userCan('update calendar events')" @click="undoConfirm">
                    {{ $t('calendar.undo_confirmation') }}
                </el-button>
            </template>

            <el-button
                v-if="!recursive"
                type="primary"
                :loading="$wait.is('updating')"
                class="mr-2 ml-2"
                :disabled="!userCan('update calendar events')"
                @click="edit"
            >
                {{ $t('calendar.edit') }}
            </el-button>
            <el-dropdown v-else class="mr-2 ml-2" @command="editEvents">
                <el-button type="primary" :loading="$wait.is('updating')">
                    {{ $t('calendar.edit') }}<i class="el-icon-caret-bottom el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="single" :disabled="!userCan('update calendar events')">{{ $t('calendar.edit_selected') }}</el-dropdown-item>
                    <el-dropdown-item command="future" :disabled="!userCan('update calendar events')">{{ $t('calendar.edit_selected_and_all_future') }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </span>
</template>

<script>
export default {
    props: {
        recursive: {
            type:    Number,
            default: 0,
        },
        cancelled: {
            type:    Number,
            default: 0,
        },
        eventsConfirmation: {
            type:    Number,
            default: 0,
        },
        confirmation: {
            type:    Number,
            default: 0,
        },
    },

    data() {
        return {
            command: '',
        };
    },

    methods: {
        deleteBtn() {
            this.$emit('deleteBtn');
        },
        deleteEvents(value) {
            this.$emit('deleteEvents', value);
        },
        restore() {
            this.$emit('restore');
        },
        unbook() {
            this.$emit('unbook');
        },
        confirm() {
            this.$emit('confirm');
        },
        undoConfirm() {
            this.$emit('undoConfirm');
        },
        edit() {
            this.$emit('edit');
        },
        editEvents(value) {
            this.$emit('editEvents', value);
        },
    },
};
</script>
